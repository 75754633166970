import { graphql } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub, faStackOverflow } from "@fortawesome/free-brands-svg-icons"
import Visible from "../components/common/Visible"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        eclipse: {
          title: string
          description: string
          image: string
          icon: string
        }
        vscode: {
          title: string
          description: string
          image: string
          icon: string
        }
        theia: {
          title: string
          description: string
          image: string
          icon: string
        }
        features: Array<{
          title: string
          description: string
        }>
        others: Array<{
          title: string
          description: string
          image: string
        }>
        links: Array<{
          title: string
          description?: string
          url: string
        }>
      }
    }
    tools: {
      nodes: Array<{
        title: string
        downloads: Array<{
          download: string
          variant: string
          label: string
          description: string
          version: string
        }>
      }>
    }
  }
}

const LinkTool = ({ title, description, link }: any) => {
  return (
    <a href={link} className="button is-spring my-2">
      {title}
      {description && <div className="description">{description}</div>}
    </a>
  )
}

const Ide = ({ ide, downloads }: any) => {
  return (
    <article className="py-6 has-big-border-bottom-light">
      <Visible className="columns item">
        <div className="column-1 column is-5 pr-6">
          <h1 className="is-size-2 has-text-weight-medium">{ide.title}</h1>
          <p className="py-5">{ide.description}</p>
          {downloads?.map((download: any, index: number) => (
            <LinkTool
              key={`link-${index}`}
              title={download.label || ""}
              description={download?.description || ""}
              link={download.download}
            />
          ))}
        </div>
        <div className="column-2 column is-7 pl-6">
          <div className="images">
            <img
              className="preview"
              src={`/img${ide?.image}`}
              alt={`${ide.title} preview`}
            />
            <img
              className="icon"
              src={`/img${ide?.icon}`}
              alt={`${ide.title}`}
            />
          </div>
        </div>
      </Visible>
    </article>
  )
}

const Feature = ({ feature }: any) => {
  return (
    <article className="column is-4 p-6">
      <h1 className="is-size-4 pb-4 has-text-weight-medium">{feature.title}</h1>
      <p>{feature.description}</p>
    </article>
  )
}

const Other = ({ other }: any) => {
  return (
    <Visible>
      <article className="item py-5 columns">
        <div className="column-1 column is-7 has-text-right py-5 pr-6">
          <img src={`/img/${other.image}`} alt={other.title} />
        </div>
        <div className="column-2 column is-5">
          <h1 className="is-size-4 pb-4 has-text-weight-medium">
            {other.title}
          </h1>
          <p>{other.description}</p>
        </div>
      </article>
    </Visible>
  )
}

// markup
const ToolsTemplate = (props: Props) => {
  const page = props.data.page
  const tools = props.data.tools

  const getDownloads = (ide: string) => {
    return tools.nodes.find(tool => ide === tool.title)?.downloads
  }

  return (
    <Layout
      seo={{
        title: page.frontmatter.title,
        description: page.frontmatter.description,
        keywords: page.frontmatter.keywords,
      }}
      className={"tool"}
    >
      <div className="hero">
        <div className="extra-0">
          <div className="extra-1">
            <img src={`/img/extra/tools-5.svg`} alt="" />
          </div>
          <div className="extra-2">
            <img className="img-light" src={`/img/extra/tools-6.svg`} alt="" />
            <img className="img-dark" src={`/img/extra/tools-6-dark.svg`} alt="" />
          </div>
        </div>
        <div className="container has-text-centered">
          <div className="content py-6 my-6">
            <img
              src={`/img/logos/spring-tool.svg`}
              alt="Spring Tool 4"
              className="logo pb-2 img-light"
            />
            <img
              src={`/img/logos/spring-tool-dark.svg`}
              alt="Spring Tool 4"
              className="logo pb-2 img-dark"
            />
            <div>{page.frontmatter.description}</div>
          </div>
        </div>
      </div>
      <div className="ide">
        <div className="container">
          <Ide
            key={`vscode-ide`}
            ide={page.frontmatter.vscode}
            downloads={getDownloads("vscode")}
          />
          <Ide
            key={`eclipse-ide`}
            ide={page.frontmatter.eclipse}
            downloads={getDownloads("eclipse")}
          />
          <Ide
            key={`theia-ide`}
            ide={page.frontmatter.theia}
            downloads={getDownloads("theia")}
          />
        </div>
      </div>
      <div className="features pb-6 mb-5">
        <div className="container">
          <h1 className="mt-6 pt-3 has-text-centered is-size-2 has-text-weight-medium">
            <img
              className="logo mr-4"
              src={"/img/projects/spring-tool.svg"}
              alt=""
            />
            Spring Tools 4
          </h1>
          <div className="columns">
            {page.frontmatter.features.map((feature: any, index: number) => (
              <Feature feature={feature} key={`feature-${index}`} />
            ))}
          </div>
        </div>
      </div>
      <div className="others has-background-light py-6">
        <div className="container">
          <div className="">
            {page.frontmatter.others.map((other: any, index: number) => (
              <Other other={other} key={`other-${index}`} />
            ))}
          </div>
          <div className="has-text-centered pt-6 pb-5">
            <a
              href={page.frontmatter.links[0].url}
              className="button is-spring px-6"
            >
              {page.frontmatter.links[0].title}
              <div className="description">
                {page.frontmatter.links[0].description}
              </div>
            </a>
          </div>
          <div className="py-6 has-text-centered">
            <a className="link mx-4" href={page.frontmatter.links[1].url}>
              <FontAwesomeIcon className="mr-3" icon={faStackOverflow} />
              {page.frontmatter.links[1].title}
            </a>
            <a className="link mx-4" href={page.frontmatter.links[2].url}>
              <FontAwesomeIcon className="mr-3" icon={faGithub} />
              {page.frontmatter.links[2].title}
            </a>
            <a className="link mx-4" href={page.frontmatter.links[3].url}>
              <FontAwesomeIcon className="mr-3" icon={faGithub} />
              {page.frontmatter.links[3].title}
            </a>
          </div>
        </div>
      </div>
      <div className="spring-tool-3 py-6">
        <div className="container py-6">
          <Visible className="spring-tool-3-content">
            <HTMLContent
              content={props.data.page.html}
              className={"content markdown links-animate"}
            ></HTMLContent>
          </Visible>
        </div>
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default ToolsTemplate

// graphQL queries
export const pageQuery = graphql`
  query ToolsTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        eclipse {
          title
          description
          image
          icon
        }
        vscode {
          title
          description
          image
          icon
        }
        theia {
          title
          description
          image
          icon
        }
        features {
          title
          description
        }
        others {
          title
          description
          image
        }
        links {
          title
          description
          url
        }
      }
    }
    tools: allContentfulTools {
      nodes {
        title
        downloads {
          download
          label
          description
          url
          variant
          version
        }
      }
    }
  }
`
